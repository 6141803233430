import { graphql } from 'gatsby';
import Posts from '../../features/blog/components/Posts';
import SiteLayout from '../SiteLayout';

const ArchivePage = ({ data, pageContext }) => (
  <SiteLayout>
    <h1>Tag Archives: {pageContext.tag}</h1>
    <Posts posts={data.allMdx.nodes} />
  </SiteLayout>
);

export default ArchivePage;

export const query = graphql`
  query MdxArchivePage($tag: String) {
    allMdx(filter: { frontmatter: { tags: { in: [$tag] } } }) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "YYYY MMMM Do")
        }
        timeToRead
      }
    }
  }
`;
